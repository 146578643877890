import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`use std::error::Error;

// Traits
trait PatientData {
    fn new(name: String, birth_date: chrono::NaiveDate) -> Self;
    fn get_name(&self) -> &str;
    fn get_birth_date(&self) -> chrono::NaiveDate;
}

// Structs
struct Patient {
    name: String,
    birth_date: chrono::NaiveDate,
}

// Implementations
impl PatientData for Patient {
    fn new(name: String, birth_date: chrono::NaiveDate) -> Self {
        Self { name, birth_date }
    }
    fn get_name(&self) -> &str {
        &self.name
    }
    fn get_birth_date(&self) -> chrono::NaiveDate {
        self.birth_date
    }
}

// Enums
enum Fruit {
    Apple,
    Banana,
    Orange,
}

// Variables and data types
fn main() -> Result<(), Box<dyn Error>> {
    let name = "John Smith".to_string();
    let birth_date = chrono::NaiveDate::from_ymd(1970, 1, 1);
    let is_new_patient = true;

    // Conditional statements
    if is_new_patient {
        println!("Is new patient");
    } else {
        println!("Is returning patient");
    }

    // Lists and hash maps
    let fruits = vec![Fruit::Apple, Fruit::Banana, Fruit::Orange];
    let mut patient_data = std::collections::HashMap::new();
    patient_data.insert("name", name);
    patient_data.insert("age", age);
    patient_data.insert("is_new_patient", is_new_patient);

    // Loops
    for fruit in fruits {
        match fruit {
            Fruit::Apple => println!("Apple"),
            Fruit::Banana => println!("Banana"),
            Fruit::Orange => println!("Orange"),
        }
    }

    // Functions
    let result = add_numbers(3, 5);
    println!("The result is {}", result);

    // Exception Handling
    let patient = Patient::new(name.clone(), birth_date);
    let result = patient.update_name("");
    match result {
        Ok(_) => println!("Name successfully updated"),
        Err(e) => println!("Error: {}", e),
    }

    Ok(())
}

fn add_numbers(x: i32, y: i32) -> i32 {
    x + y
}

// Methods and associated functions
impl Patient {
    fn update_name(&self, new_name: &str) -> Result<(), &'static str> {
        if new_name.is_empty() {
            return Err("Name can not be empty");
        }
        Ok(())
    }
}

// Creating an object
let p1 = Patient {
    name: name,
    age: age,
};

// Using object's properties
println!("{}", p1.name);
println!("{}", p1.age);

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      